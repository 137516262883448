.app {
  margin: 0;
  padding: 0;
}

.bigScreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
}

.bigScreenText {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: black;
  font-size: 6vw;
}
