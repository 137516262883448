.privacyPolicy {
  font-family: Arial, sans-serif;
  padding: 20px;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  overflow: auto;
}

.title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #0a5360;
  text-align: center;
}

.subtitle {
  font-size: 22px;
  margin-top: 20px;
  color: #074e5c;
}

.section {
  margin-bottom: 20px;
}

a {
  color: #0a5360;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

strong {
  font-weight: bold;
}
