.gameTree {
  background-image: url("../../../public/assets/backGameTree.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%; /* Ширина элемента */
  height: 100vh; /* Высота элемента */
  position: relative;
}

.blockBack {
  position: absolute;
  width: 100%;
  top: 2%;
  right: 5%;
  display: flex;
  justify-content: flex-end;
}

.imageBack {
  transform: rotate(90deg);
  height: auto;
  width: 10vw;
}

.gameTreeBlock {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.countBlock {
  width: 22vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counts {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.imageCount {
  width: auto;
  height: 33vh;
}

.buttonSpinBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%);
  z-index: 20;
}

.imageSpin {
  width: 16vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
  z-index: 20;
}

.imageSpin:active {
  transform: scale(
    0.98
  ); /* Немного уменьшает размер кнопки для эффекта нажатия */
}

.balanceBlock {
  position: relative;
}

.balanceTextBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 47vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}

.balanceText {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 3.5vw;
}

.winBlock {
  position: relative;
}

.winTextBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 47vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}

.winText {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 3.5vw;
}

.imageSlots {
  width: 45vw;
  height: auto;
}

.slotsBlock {
  height: 100vh;
  width: 45vw;
}

.slots {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slotsMachine {
  position: absolute;
}

.selectWildBlock {
  height: 100vh;
  width: 18vw;
  display: flex;
  align-items: center;
}

.selectWild {
  position: relative;
}

.selectWildTextBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 10%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  display: flex;
  /* width: 40vw; */
  flex-direction: column;
  z-index: 10;
}

.selectWildText {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 3.5vw;
}

.imageSelectWild {
  width: 17vw;
  height: 56vh;
}

.changeWildBlock {
  position: absolute;
  top: 72%;
  height: 23%;
  width: 19vw;
  display: flex;
  justify-content: center;
}

.wildImageBlock {
  position: absolute;
  top: 35%;
  height: 23%;
  width: 18vw;
  display: flex;
  justify-content: center;
}

.imageButtonChange {
  width: 10vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imageButtonChange:active {
  transform: scale(
    0.98
  ); /* Немного уменьшает размер кнопки для эффекта нажатия */
}

.popupSelectWildBlock {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(16, 16, 16, 0.3);
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageFrameSelectWild {
  width: 80vw;
  height: auto;
}

.popupSelectWild {
  position: relative;
}

.ChoiseBlock {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
}

.textPopupBlock {
  width: 20vw;
  position: relative;
}

.textPopup {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 10%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  display: flex;
  width: 60vw;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.text {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 6vw;
}

.selectWildOne {
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
  width: 22vw;
  align-items: center;
}

.selectWildTwo {
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
  width: 22vw;
  align-items: center;
}

.buttonPopup {
  width: 20vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.imageWildx {
  width: 17vw;
  height: auto;
}

.imageWildFrame {
  width: 10vw;
  height: auto;
}

.imageButtonChoise {
  width: 10vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imageButtonChoise:active {
  transform: scale(
    0.98
  ); /* Немного уменьшает размер кнопки для эффекта нажатия */
}

.betBlock {
  width: 18vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.betNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plusBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 7vh;
}

.frameBetBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.minusBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 7vh;
}

.imageFrameBet {
  height: 20vh;
  width: auto;
}

.imageBet {
  height: auto;
  width: 11vw;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imageBet:active {
  transform: scale(
    0.98
  ); /* Немного уменьшает размер кнопки для эффекта нажатия */
}

.betTextBlock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  transform-origin: center;
  width: 27vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}

.betText {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 3.5vw;
}
