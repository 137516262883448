.settings {
  background-image: url("../../../public/assets/back.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%; /* Ширина элемента */
  height: 100vh; /* Высота элемента */
  position: relative;
  overflow: hidden;
}

.blockBack {
  position: absolute;
  width: 100%;
  top: 2%;
  left: 5%;
}

.settingsBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.settingsComponents {
  position: relative;
}

.imageFrame {
  width: 90vw;
  height: auto;
  max-width: 100%;
}

.blockTitle {
  position: absolute;
  top: 0;
  width: 100%;
}

.blockTitlePos {
  padding-top: 25%;
  display: flex;
  justify-content: center;
}

.textTitle {
  font-family: "Istok Web", sans-serif;
  font-weight: 600;
  color: #ffc700;
  font-size: 11vw;
  justify-content: center;
}

.blockMusic {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 20;
}

.music {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  align-items: center;
  padding-top: 45%;
}

.sound {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  align-items: center;
  padding-top: 10%;
}

.border {
  border: 2px solid #ffc700;
  width: 44vw;
  height: 3vh;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.firstLayer {
  width: 44vw;
  height: 3vh;
  background-color: #fa05e1;
  border-radius: 20px;
}

.secondLayer {
  width: 44vw;
  height: 3vh;
  background-color: #ffc700;
  border-radius: 20px;
  z-index: 10;
  position: absolute;
}

.blockScroll {
  position: absolute;
  right: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
}

.imageScroll {
  width: auto;
  height: 4vh;
}

.blockReset {
  position: absolute;
  top: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 103%;
}

.text {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 7vw;
  justify-content: center;
  text-decoration: underline;
}

.popupResScore {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  transition: top 0.5s ease-out;
}

.popupResScoreBlock {
  width: 60vw;
  height: 6vh;
  border-radius: 5vw;
  background-color: rgba(255, 225, 225, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.textPopup {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 7vw;
  justify-content: center;
}

.popupResScore.active {
  top: 3%;
}
