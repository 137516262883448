.menu {
  background-image: url("../../../public/assets/back.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%; /* Ширина элемента */
  height: 100vh; /* Высота элемента */
}

.menuBlock {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.blockSettings {
  display: flex;
  padding-top: 10px;
  justify-content: flex-end;
  padding-right: 10px;
  height: 8vh;
}

.imageSettings {
  height: 7vh;
  width: auto;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imageSettings:active {
  transform: scale(
    0.98
  ); /* Немного уменьшает размер кнопки для эффекта нажатия */
}

.gamesBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.imageFrame {
  width: auto;
  height: 27vh;
  max-width: 100%;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imageFrame:active {
  transform: scale(
    0.98
  ); /* Немного уменьшает размер кнопки для эффекта нажатия */
}

.imageGameOne {
  width: 40vw;
  height: auto;
  max-width: 100%;
}

.imageGameTwo {
  width: 56vw;
  height: auto;
  max-width: 100%;
}

.imageGameTree {
  width: 50vw;
  height: auto;
  max-width: 100%;
}

.blockGameImageOne {
  position: absolute;
  top: 0;
  z-index: 10;
  padding-top: 10%;
}

.blockGameImageTwo {
  position: absolute;
  top: 0;
  z-index: 10;
  padding-top: 10%;
}

.blockGameImageTree {
  position: absolute;
  top: 0;
  z-index: 10;
  padding-top: 9%;
}

.blockTextOne {
  position: absolute;
  top: 0;
  z-index: 10;
  padding-top: 33%;
}

.blockTextTwo {
  position: absolute;
  top: 0;
  z-index: 10;
  padding-top: 33%;
}

.blockTextTree {
  position: absolute;
  top: 0;
  z-index: 10;
  padding-top: 33%;
}

.text {
  font-family: "Istok Web", sans-serif;
  font-weight: 600;
  color: #ffc700;
  font-size: 7vw;
}
