.gameOne {
  background-image: url("../../../public/assets/backGameOne.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%; /* Ширина элемента */
  height: 100vh; /* Высота элемента */
  position: relative;
}

.blockBack {
  position: absolute;
  width: 100%;
  top: 2%;
  right: 5%;
  display: flex;
  justify-content: flex-end;
}

.imageBack {
  transform: rotate(90deg);
  height: auto;
  width: 10vw;
}

.gameOneBlock {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.navigationBlock {
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.balanceBlock {
  position: relative;
  height: 30vh;
  width: 15vw;
  background-color: #d9d9d9;
  border-bottom-right-radius: 10vw;
}

.balance {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 30vw;
  height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: black;
  font-size: 3.5vw;
}

.navigateBlock {
  height: 30vh;
  width: 15vw;
  background-color: #d9d9d9;
  border-top-right-radius: 10vw;
  border-bottom-right-radius: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.betBlock {
  position: relative;
  height: 30vh;
  width: 15vw;
  background-color: #d9d9d9;
  border-top-right-radius: 10vw;
}

.bet {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  padding: 0;
  width: 30vw;
  height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blockSlots {
  width: 75vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imageFrameForSlots {
  width: 65vw;
  height: auto;
}

.emptyBlock {
  width: 100%;
  height: 20%;
}

.slots {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.imageSVG {
  transform: rotate(90deg);
  width: 9vw;
  height: auto;
}

.imageSpin {
  width: 20vw;
  height: auto;
  transform: rotate(90deg);
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imageSpin:active {
  transform: scale(
    0.98
  ); /* Немного уменьшает размер кнопки для эффекта нажатия */
}

.imageBetSVGBlock {
  padding-top: 20%;
  padding-left: 5%;
}

.imageAutoplaySVGBlock {
  padding-bottom: 20%;
  padding-left: 5%;
}

.spin {
  padding-left: 35%;
}

.tableLineBlock {
  width: 60vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageTableLine {
  width: 40vw;
  height: auto;
}

.winPageBlock {
  height: 100%;
  width: 15vw;
  position: relative;
}

.win {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
}

.cancellBlock {
  height: 100%;
  width: 10vw;
  position: relative;
}

.cancellImage {
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.slotsMachine {
  position: absolute;
  right: 0;
}

.betBlockNav {
  height: 20%;
  width: 100%;
}

.betNav {
  display: flex;
  flex-direction: row;
}

.winLine {
  height: 17vh;
  width: 30%;
  position: relative;
}

.winLineBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 32vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
}

.winLineText {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: white;
  font-size: 5vw;
  text-decoration: underline;
}

.betNavigate {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.betNavBack {
  width: 22vw;
  height: 18vh;
  background-color: rgba(255, 225, 225, 0.3);
  border-radius: 5vw;
  position: relative;
}

.buttonBetNav {
  transform: rotate(90deg);
}

.betBlockText {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  padding: 0;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.betText {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: #2e2e2e;
  font-size: 6vw;
}

.popupBetBlock {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(16, 16, 16, 0.3);
  z-index: 10;
}

.popupBet {
  position: absolute;
  top: 29%;
  left: 15%;
  z-index: 20;
}

.imagePopup {
  width: 40vw;
  height: auto;
}

.betNavFrameBlock {
  position: relative;
  width: 40vw;
}

.nameFrame {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 90%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  padding: 0;
  width: 30vw;
  /* height: 6vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nameFrameText {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: #2e2e2e;
  font-size: 4vw;
}

.navigate {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 55%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  padding: 0;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nameFrameTextTwo {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: white;
  font-size: 4vw;
}

.navForBet {
  position: absolute;
  top: 4%;
  left: 18%;
}

.imageNavBet {
  height: auto;
  width: 9.2vw;
}

.navForBetBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 49%; /* Центрируем вертикально относительно родителя */
  left: 28%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  padding: 0;
  width: 37vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.nameFrameTextTwoBet {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: white;
  font-size: 4vw;
}

.popupAutoPlayBlock {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(16, 16, 16, 0.3);
  z-index: 40;
}

.popupAutoplay {
  position: absolute;
  top: 50%;
  left: 15%;
}

.autoPlayNavFrameBlock {
  position: relative;
  width: 40vw;
}

.autoplayTextBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 48%; /* Центрируем вертикально относительно родителя */
  left: 62%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  padding: 0;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.autoplayText {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: white;
  font-size: 3vw;
}

.autoplayBetBlock {
  position: absolute;
  top: 14%;
  left: 20%;
  display: flex;
  flex-direction: row;
}

.autoplayColOne {
  padding-right: 10%;
}

.imageBetAutoplay {
  width: 6vw;
  height: auto;
}

.popupBlock {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.backWinBlock {
  position: absolute;
  width: 48vw;
  height: 35vh;
  background-color: rgba(7, 7, 7, 0.62);
}

.backWin {
  width: 48vw;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWin {
  width: auto;
  height: 19vh;
}

.imageLightWin {
  width: 40vw;
  height: auto;
}

.winResTextBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  padding: 0;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.winResText {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: #fff532;
  font-size: 9vw;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000,
    1px -1px 0 #000, 1px 0 0 #000, 0 1px 0 #000, -1px 0 0 #000, 0 -1px 0 #000;
}

.blockResWin {
  position: absolute;
  width: 48vw;
  height: 35vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
