.gameOne {
  background-image: url("../../../public/assets/backGameOne.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%; /* Ширина элемента */
  height: 100vh; /* Высота элемента */
}

.gameOneBlock {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.winLineBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.winLine {
  width: 80vw;
  height: 70vh;
  background-color: rgba(255, 225, 225, 0.3);
  border-radius: 10vw;
  display: flex;
  flex-direction: row;
}

.tableBlock {
  height: 100%;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imegelines {
  width: auto;
  height: 60vh;
}

.lineBlock {
  height: 100%;
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.winPageBlock {
  height: 100%;
  width: 15vw;
  position: relative;
}

.win {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
}

.winLineText {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: #2e2e2e;
  font-size: 8vw;
}

.cancellBlock {
  height: 100%;
  width: 10vw;
  position: relative;
}

.cancellImage {
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
