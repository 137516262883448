.rewardItem {
  width: 20vw;
  padding-top: 5px;
  position: relative;
}

.rewardItemTwo {
  width: 60vw;
  padding-top: 5px;
  position: relative;
}

.textReward {
  position: absolute;
  top: 16%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18vw;
}

.textRewardTwo {
  position: absolute;
  top: 16%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;
}

.imageFrameSmoll {
  width: 18vw;
  height: auto;
  max-width: 100%;
}

.imageFrameLongSmoll {
  width: 60vw;
  height: auto;
  max-width: 100%;
}

.textDay {
  font-family: "Istok Web", sans-serif;
  font-weight: 600;
  color: #ffc700;
  font-size: 4vw;
}

.textCount {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 3vw;
}

.rewardImgSVG {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  width: 20vw;
}

.rewardImgSVGTwo {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  width: 60vw;
}
