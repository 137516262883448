.slotsMachine {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transform: rotate(90deg);
}

.slotBlock {
  width: 12vw;
  height: 20vh;
  overflow: hidden;
  margin: 0 5px;
}

.slotInner {
  display: flex;
  flex-direction: column;
}

.slotsItem {
  position: relative;
  width: 100%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slotsItemImage {
  width: 11vw;
  height: 5vh;
}

.lightSlotsImage {
  height: 100%;
  width: 100%;
}
