.gameTwo {
  background-image: url("../../../public/assets/backGameTwo.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%; /* Ширина элемента */
  height: 100vh; /* Высота элемента */
  position: relative;
}

.blockBack {
  position: absolute;
  width: 100%;
  top: 2%;
  right: 2%;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
}

.imageBack {
  transform: rotate(90deg);
  height: auto;
  width: 10vw;
}

.gameTwoBlock {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.navigationBlock {
  height: 75vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.winningLinesBlock {
  position: relative;
  height: 33%;
}

.winningLines {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.winningLinesText {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 6vw;
  justify-content: center;
  text-decoration: underline;
  text-shadow: 2px 5px 4px rgba(0, 0, 0, 0.5);
}

.betBlock {
  position: relative;
  height: 67%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.betCount {
  position: absolute;
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%);
  z-index: 20;
}

.imageNav {
  width: 15vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imageNav:active {
  transform: scale(
    0.98
  ); /* Немного уменьшает размер кнопки для эффекта нажатия */
}

.imageBet {
  width: 20vw;
  height: auto;
  z-index: 10;
}

.betTextBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
}

.betText {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 4vw;
}

.betTextCount {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 6vw;
}

.imageSlots {
  width: 43vw;
  height: auto;
}

.slotsBlock {
  width: 43vw;
}

.countBlock {
  width: 25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.counts {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.imageCount {
  width: auto;
  height: 33vh;
}

.imageSpin {
  width: 16vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imageSpin:active {
  transform: scale(
    0.98
  ); /* Немного уменьшает размер кнопки для эффекта нажатия */
}

.balanceBlock {
  position: relative;
}

.balanceTextBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 47vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}

.balanceText {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 3vw;
}

.winBlock {
  position: relative;
}

.winTextBlock {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 47vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}

.winText {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 3vw;
}

.winLineBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.winLine {
  width: 80vw;
  height: 70vh;
  background-color: rgba(16, 16, 16, 0.3);
  border-radius: 10vw;
  display: flex;
  flex-direction: row;
}

.diagonalBlock {
  height: 100%;
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imegelines {
  width: 20vw;
  height: auto;
}

.lineBlock {
  height: 100%;
  width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.winPageBlock {
  height: 100%;
  width: 15vw;
  position: relative;
}

.win {
  position: absolute; /* Устанавливаем абсолютное позиционирование для дочернего блока */
  top: 50%; /* Центрируем вертикально относительно родителя */
  left: 50%; /* Центрируем горизонтально относительно родителя */
  transform: translate(-50%, -50%) rotate(90deg); /* Центрируем и вращаем блок */
  transform-origin: center;
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
}

.winLineText {
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: #2e2e2e;
  font-size: 7vw;
}

.cancellBlock {
  height: 100%;
  width: 10vw;
  position: relative;
}

.cancellImage {
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.slots {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slotsMachine {
  position: absolute;
}
