.privacy {
  background-image: url("../../../public/assets/back.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%; /* Ширина элемента */
  height: 100vh; /* Высота элемента */
}

.privacyBlock {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.privacyText {
  margin: 0;
  padding: 0;
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: white;
  font-size: 12vw;
  text-decoration-line: underline;
}

.buttonBlock {
  position: absolute;
  bottom: 0;
  padding-bottom: 25%;
}

.buttonImage {
  height: auto;
  width: 50vw;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.buttonImage:active {
  transform: scale(
    0.98
  ); /* Немного уменьшает размер кнопки для эффекта нажатия */
}

.privacyArtical {
}
