.slotsMachine {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transform: rotate(90deg);
}

.slotBlock {
  width: 28vw;
  height: 19vh;
  overflow: hidden;
  margin: 0 1.5vw;
}

.slotInner {
  display: flex;
  flex-direction: column;
}

.slotsItem {
  position: relative;
  width: 100%;
  height: 6.3vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slotsItemImage {
  width: 25vw;
  height: 6vh;
}
