.spriteAnimation {
  position: relative;
  width: 25vw;
  height: 6vh;
}

.frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.frame.visible {
  opacity: 1;
}

.frame.hidden {
  opacity: 0;
}
