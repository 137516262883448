.reward {
  background-image: url("../../../public/assets/back.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%; /* Ширина элемента */
  height: 100vh; /* Высота элемента */
}

.rewardBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.dailyReward {
  position: relative;
}

.imageFrame {
  width: 90vw;
  height: auto;
  max-width: 100%;
}

.blockTitle {
  position: absolute;
  top: 0;
  width: 100%;
}

.blockTitlePos {
  padding-top: 25%;
  display: flex;
  justify-content: center;
}

.textTitle {
  font-family: "Istok Web", sans-serif;
  font-weight: 600;
  color: #ffc700;
  font-size: 11vw;
}

.getReward {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.blockGetReward {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  padding-top: 40%;
  width: 68%;
}

.blockGetRewardFirstLine {
  position: absolute;
  top: 0;
  padding-top: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.blockGetRewardThirdLine {
  position: absolute;
  top: 0;
  padding-top: 72%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.imageFrameLongSmoll {
  width: 60vw;
  height: auto;
  max-width: 100%;
}
