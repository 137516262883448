.loading {
  background-image: url("../../../public/assets/back.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%; /* Ширина элемента */
  height: 100vh; /* Высота элемента */
}

.loadingBlock {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadingText {
  margin: 0;
  padding: 0;
  font-family: "Istok Web", sans-serif;
  font-weight: 700;
  color: #b2275d;
  font-size: 13vw;
  text-shadow: 2px 5px 4px rgba(0, 0, 0, 0.5);
}

.animateBlock {
  height: 4vh;
  width: 90%;
  border-radius: 20px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  padding-inline: 10px;
}

.backgroundLoading {
  height: 3vh;
  width: 60%;
  background-color: #b2275d;
  border-radius: 3vw;
}
